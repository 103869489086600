@import '@datum-css/css/src/variables.scss';
@import './brand-colors';
@import './utilities';
@import './base';
@import './app';
@import './antd';
//components
@import './components/page';
@import './components/forms';
@import './components/form-error-list';
@import './components//service-list-table';
//Pages
@import './pages/search';
@import './pages/dashboard';
@import './pages/hcfa-paper';
@import './pages/ub04-paper';
@import './pages/home';
@import './pages/branding-page';
@import './pages/archive';
//themes
@import './themes/theme-branding';
@import './themes/hap-caresource';
@import './themes/truecare';

.app-page__content {
  .ant-space {
    z-index: 10;
  }
}

.ant-tabs-content-holder {
  overflow-y: auto;
}

.HCFA_Npi-field {
  box-shadow: 0 0 0 2px #ef955194;
  &:focus {
    box-shadow: 0 0 0 2px #ef955194;

    border-color: #ef9651;
  }
  &:hover {
    border-color: #ef9651;
  }
}

.RenderProvider__popconfirm-wrapper {
  position: relative;
  .RenderProvider__popconfirm {
    position: absolute;
    right: calc(100% + 8px);
    top: -5px;
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 170px;
    background-color: #fff;
    height: 34px;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 0px 8px;
    font-size: 12px;
    justify-content: space-between;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #fff;
    }
  }
}
